.footnav {
  width: 100%;
}
.footnav .el-backtop__icon {
  color: var(--themeColor) !important;
}
.footnav .nav {
  background-color: #ecf3f3;
  padding: 0.6rem 0 0.32rem;
}
.footnav .nav .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.footnav .nav .container .footer-nav-one .linkone {
  color: var(--titleColor);
  font-weight: bold;
  margin-bottom: 0.16rem;
  display: block;
  font-size: var(--f18);
}
.footnav .nav .container .footer-nav-one .footer-nav-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.footnav .nav .container .footer-nav-one .footer-nav-two .linktwo {
  font-size: var(--f14);
  color: var(--descColor);
  margin-bottom: 0.06rem;
  cursor: pointer;
}
.footnav .nav .container .footer-nav-one .footer-nav-two .linktwo:hover {
  color: var(--themeColor);
}
.footnav .common {
  width: 15rem;
  margin: 0 auto;
}
.footnav .copyright {
  font-size: var(--f14);
  color: #fff;
  background: var(--themeColor);
  position: relative;
}
.footnav .copyright .container {
  padding: 0.3rem 0 0.38rem;
}
.footnav .copyright .container .unitlist {
  margin-bottom: 0.28rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.footnav .copyright .container .unitlist-item {
  height: 0.6rem;
  margin-right: 0.36rem;
}
.footnav .copyright .container .unitlist-item img {
  width: 100%;
  height: 100%;
}
.footnav .copyright .container .link {
  margin-bottom: 0.1rem;
}
.footnav .copyright .container .link a {
  color: #fff;
}
.footnav .copyright .container .link a:not(:last-child)::after {
  content: "|";
  font-size: var(--f14);
  display: inline-block;
  padding: 0 0.06rem;
}
.footnav .copyright .container .copyinfo {
  margin-bottom: 0.1rem;
  font-size: var(--f14);
  color: #fff;
}
.footnav .copyright .container .copyinfo .icon {
  width: 0.18rem;
  height: 0.18rem;
  display: inline-block;
  margin-right: 0.1rem;
  vertical-align: text-bottom;
}
.footnav .copyright .container .iconlist {
  position: absolute;
  right: calc((100% - 12rem) / 2);
  bottom: 0.3rem;
}
.footnav .copyright .container .iconlist .icon {
  width: 0.28rem;
  height: 0.32rem;
  display: inline-block;
  margin-right: 0.1rem;
}
.footnav .copyright .container .iconlist .copyinfo {
  margin-top: 0.2rem;
  font-size: var(--f14);
  color: #fff;
  line-height: 0.2rem;
}
.footnav .copyright .container .iconlist .copyinfo .icon {
  width: 0.18rem;
  height: 0.18rem;
  display: inline-block;
  margin-right: 0.1rem;
}