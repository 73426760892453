.headnav {
  height: 0.7rem;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 999;
  color: #5e5c5d;
  font-size: var(--f16);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 0.15rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.headnav .headnavCont {
  width: 15rem;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.headnav .headnavCont .logo {
  display: inline-block;
  height: 0.3rem;
  margin-right: 0.46rem;
}
.headnav .headnavCont .navlist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.headnav .headnavCont .search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 0.28rem;
  height: 0.28rem;
  font-size: 0.16rem;
  cursor: pointer;
}
.el-menu--horizontal {
  border-bottom: unset !important;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: space-evenly !important;
  -ms-flex-pack: space-evenly !important;
  justify-content: space-evenly !important;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
  color: var(--themeColor);
  background: unset;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  background-color: unset;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  color: var(--themeColor) !important;
}
.el-menu--horizontal > .el-menu-item {
  font-size: var(--f16);
  padding: 0;
}
.el-menu--horizontal > .el-menu-item:hover {
  color: var(--themeColor);
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: unset !important;
}
.el-menu--horizontal > .el-menu-item.is-active:hover {
  color: var(--themeColor) !important;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-sub-menu__title {
  color: var(--titleColor) !important;
}
.el-menu--horizontal .el-menu .el-menu-item:hover,
.el-menu--horizontal .el-menu .el-sub-menu__title:hover {
  color: var(--themeColor) !important;
}
.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
  border-bottom: unset !important;
}
.el-sub-menu__title {
  padding: 0;
  font-size: var(--f16);
}